<template>
  <b-modal
    id="add-currency-modal"
    body-class="add-currency-modal"
    hide-footer
    hide-header-close
    centered
    no-close-on-backdrop
    :title="$t('currencies.add.head')"
  >
    <overlay-component :is-loading="isBusy" />
    <validation-observer ref="addCurrency">
      <b-form>
        <b-form-group :label="$t('currencies.add.currencies')">
          <validation-provider :name="$t('currencies.add.currencies')">
            <multiselect
              v-model="currency"
              :placeholder="$t('currencies.add.currenciesPlaceHolder')"
              :options="currencies"
              label="text"
              track-by="text"
              :multiple="false"
              :taggable="true"
              :custom-label="textWithLang" /></validation-provider
        ></b-form-group>
        <b-form-group :label="$t('currencies.add.countries')">
          <validation-provider :name="$t('currencies.add.countries')">
            <multiselect
              v-model="country"
              :placeholder="$t('currencies.add.currenciesPlaceHolder')"
              :options="countries"
              label="name"
              track-by="name"
              :multiple="true"
              :taggable="true"
              :custom-label="nameWithLang" /></validation-provider
        ></b-form-group> </b-form
    ></validation-observer>
    <b-row class="d-flex justify-content-end px-1">
      <b-button
        variant="outline-primary mr-1 "
        :disabled="isBusy"
        @click="handleCancel"
      >
        {{ $t("currencies.add.cancel") }}
      </b-button>
      <b-button variant="primary" :disabled="isBusy" @click="validateForm">
        {{ $t("currencies.add.add") }}
      </b-button>
    </b-row>
  </b-modal>
</template>
<script>
import {
  BFormSelect,
  BFormGroup,
  BForm,
  BModal,
  BFormSelectOption,
  BButton,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import overlayComponent from "@/components/shared/OverlayComponent/index.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "AddCurrencyModal",
  components: {
    Multiselect,
    BFormSelect,
    BFormGroup,
    BForm,
    BModal,
    ValidationObserver,
    ValidationProvider,
    overlayComponent,
    BFormSelectOption,
    BRow,
    BButton,
  },
  data() {
    return {
      currencies: [],
      countries: [],
      isBusy: false,
      currency: null,
      country: null,
    };
  },
  created() {
    this.getAllRecourses();
  },
  methods: {
    async getAllRecourses() {
      this.isBusy = true;
      try {
        const responseCurrencies = await this.$http.get("admin/currencies");
        const responseCountries = await this.$http.get("admin/countries");
        if (
          (responseCurrencies.status === 200 ||
            responseCurrencies.status === 201) &&
          (responseCountries.status === 200 || responseCountries.status === 201)
        ) {
          responseCurrencies.data.data.forEach((currency) => {
            this.currencies.push({
              text: currency.currency_code,
              value: currency.id,
            });
          });
          responseCountries.data.data.forEach(({ id, name }) => {
            this.countries.push({
              name,
              id,
            });
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    async validateForm() {
      try {
        const validation = await this.$refs.addCurrency.validate();
        if (validation) {
          this.submit();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      this.isBusy = true;

      try {
        const formData = new FormData();
        for (const country in this.country) {
          formData.append(`countries[${country}]`, this.country[country].id);
        }
        formData.append("currency_id", this.currency.value);
        const request = await this.$http.post("admin/add-currency", formData);
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast(
            "success",
            request.data.message,
            request.data.message
          );
          this.country = null;
          this.currency = null;
          this.$emit("reset-table");
          this.$bvModal.hide("add-currency-modal");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    handleCancel() {
      this.country = null;
      this.currency = null;
      this.$bvModal.hide("add-currency-modal");
    },
    nameWithLang({ name, language }) {
      return `${name}`;
    },
    textWithLang({ text, language }) {
      return `${text}`;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
