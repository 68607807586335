<template>
  <div class="coupons-list">
    <content-not-view v-if="!subPermission.index" />
    <loading v-else-if="isLoading" />
    <b-card v-else>
      <overlay-component :is-loading="isBusy" />
      <b-row class="d-flex justify-content-between p-1 align-items-end">
        <h4>
          {{ $t("currencies.head") }}
        </h4>
        <b-button
          v-if="subPermission.store"
          variant="primary"
          @click="$bvModal.show('add-currency-modal')"
        >
          {{ $t("currencies.addBtn") }}
        </b-button>
      </b-row>
      <b-row class="d-flex justify-content-between p-1">
        <b-form-input
          v-model="searchTerm"
          class="col-9"
          :placeholder="$t('g.searchHere')"
        />
        <b-form-select
          v-model="filter"
          :options="filterOptions"
          class="col-2"
        />
      </b-row>
      <vue-good-table
        :columns="tableHead"
        :rows="tableBody"
        style-class="vgt-table striped "
        :search-options="{
          enabled: true,
          searchFn: searchFunction,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'countries'">
            {{ props.row.countries() }}
          </span>
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                v-if="subPermission.update || subPermission.destroy"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="subPermission.update"
                  :to="{ name: 'EditCurrency', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
        <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <add-curencies-modal @reset-table="getCouponsData" />
  </div>
</template>
<script>
import {
  BFormSelect,
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormInput,
  BRow,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import loading from "@/components/loading/loading.vue";
import overlayComponent from "@/components/shared/OverlayComponent/index.vue";
import AddCurenciesModal from "../add/index.vue";

export default {
  name: "CouponsList",
  components: {
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BCard,
    BButton,
    BFormInput,
    BRow,
    BFormDatepicker,
    loading,
    overlayComponent,
    AddCurenciesModal,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      tableHead: [
        {
          label: "#",
          field: "i",
        },
        {
          label: this.$t("currencies.name"),
          field: "name",
        },
        {
          label: this.$t("currencies.value"),
          field: "value",
        },
        {
          label: this.$t("currencies.countries"),
          field: "country",
        },
        {
          label: this.$t("currencies.actions"),
          field: "actions",
        },
      ],
      tableBody: [],
      searchTerm: "",
      pageLength: 20,
      filterOptions: [
        {
          value: null,
          text: this.$t("currencies.searchBy.default"),
          disabled: true,
        },
        { value: "name", text: this.$t("currencies.searchBy.currencyCode") },
        { value: "country", text: this.$t("currencies.searchBy.countryName") },
      ],
      filter: "name",
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "coupons");
      return this.$store.state.userData.sub_Permission;
    },
  },
  created() {
    this.getCouponsData();
  },
  methods: {
    async getCouponsData() {
      this.isLoading = true;
      try {
        const request = await this.$http.get("admin/get-active-currency");
        if (request.status === 200 || request.status === 201) {
          this.tableBody = [];
          request.data.data.forEach((ele, i) => {
            this.tableBody.push({
              i: i + 1,
              id: ele.id,
              name: ele.currency_code,
              value: ele.exchange_value,
              is_default: ele.is_default,
              country: ele.countries.name,
            });
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    deleteAlert(id) {
      this.$helpers.sweetAlertConfirm(
        this.$t("g.are_you_sure"),
        this.$t("g.you_wont_be_able_to_revert_this"),
        "warning",
        this.$t("g.yes_delete_it"),
        this.$t("g.cancel"),
        () => this.deleteCoupon(id)
      );
    },
    async deleteCoupon(id) {
      try {
        const request = await this.$http.delete(`admin/currencies/${id}`);
        if (request.status === 200 || request.status === 201) {
          this.getCouponsData();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
      }
    },
    async updateCouponActivity(id, state) {
      try {
        this.isBusy = true;
        const formData = new FormData();
        formData.append("is_active", state ? 1 : 0);
        await this.$http.post(`admin/coupons/${id}?_method=put`, formData);
        this.$helpers.makeToast(
          "success",
          this.$t("g.send.successBody"),
          this.$t("g.send.successTitle")
        );

        this.toggleItemiseActive(id);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    toggleItemiseActive(id) {
      this.tableBody.forEach((ele) => {
        if (ele.id === id) {
          ele.is_active = true;
        }
      });
    },
    searchFunction(row, col, cellValue, searchTerm) {
      if (
        row[this.filter].includes(searchTerm) ||
        row[this.filter].toLowerCase().includes(searchTerm)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.options {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
h4 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
</style>
